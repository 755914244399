<template>
  <div class="article-detail">
    <section class="section main">
      <div class="content">
        <div>content</div>
      </div>
    </section>
    <div class="share">
      <div>
        <ul>
          <li class="icon">
            <el-tooltip class="item" effect="light" content="点赞" placement="right-start">
              <i class="el-icon-star-off"></i>
            </el-tooltip>
          </li>
          <li class="icon">
            <el-tooltip class="item" effect="light" content="评论" placement="right-start">
              <i class="el-icon-edit"></i>
            </el-tooltip>
          </li>
          <li class="icon">
            <el-tooltip class="item" effect="light" content="分享" placement="right-start">
              <i class="el-icon-share"></i>
            </el-tooltip>

          </li>
          <li class="icon">
            <el-tooltip class="item" effect="light" content="送礼" placement="right-start">
              <i class="el-icon-present"></i>
            </el-tooltip>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {article} from '@/api/apis'

export default {
  name: 'article-detail',
  data() {
    return {}
  },
  methods: {},
  created() {
    const query = this.$route.query || {}

    if (!query.articleid) {
      this.$router.push({path: '/articles'})
    }

    this.$axios.get(article.detail, {
          params: {
            userid: query.articleid
          }
        })
        .then(item => {
          let result = '';
          if (item.Code === 200) {
            result = item.Data;
            console.log(result);
          }

        })
        .catch(err => {
          console.log(err)
        })
    this.$axios.get()
  }
}
</script>
<style lang="less" scoped>
.article-detail {
  min-height: 840px;
  position: relative;

  .main {
    background-color: #fff;
    width: 1000px;
    min-height: 900px;
  }

  .share {
    width: 40px;
    position: fixed;
    top: 200px;
    right: 290px;

    .icon {
      font-size: 30px;
      padding: 8px 0;
      color: #606266;

      :hover {
        color: #409eff;
      }
    }

    .float {
      color: #409eff;
    }
  }
}
</style>